import React from 'react';

function LsfbContPage(props) {
  return (
    <section className="bg-white py-8">
      <div className="container py-8 px-6 mx-auto">
        <h1 className="uppercase tracking-wide font-bold text-gray-800 text-xl mb-8">
          LSFB-CONT
        </h1>
        <p className="mt-8 mb-8">
          We are still seeking an efficient way to distribute the LSFB-CONT dataset. If you need it rapidly please <a className="text-teal-500 hover:text-black" href="mailto:jerome.fink@unamur.be">send us an email</a>.
        </p>
      </div>
    </section>
  );
}

export default LsfbContPage