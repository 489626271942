import React from 'react';

class DownloadForm extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      accepted: false
    };
  }



  render() {
    let button;

    if (this.state.accepted) {
      button = <div className="content-center">
        <a href="https://lsfb-team.github.io/lsfb-dataset/download/" class="inline-block mt-10  bg-teal-500 text-xl px-8 py-4 leading-none border rounded text-white border-white hover:bg-teal-700 mr-2">Download dataset</a>

      </div>
    }
    else {
      button = <div className="content-center">
        <p className="inline-block mt-10  bg-gray-400 text-xl px-8 py-4 leading-none border rounded text-white border-white mr-2">Download dataset</p>
      </div>
    }

    return (
      <section className="bg-white py-8" id="Download">
        <div className="container py-8 px-6 mx-auto border-t">
          <h1 className="uppercase tracking-wide font-bold text-gray-800 text-xl mb-8">
            Download Datasets
          </h1>

          <p className="mt-8 mb-2">
            Both LSFB-CONT and LSFB-ISOL are distributed under the <a className="text-teal-700 underline hover:text-black" href="https://creativecommons.org/licenses/by-nc-sa/4.0/"> Creative Commons 4.0 license</a>. In a nutshell, it means you are free to use the LSFB dataset if you respect the following conditions :
          </p>
          <ul className="list-disc ml-8 mb-5">
            <li>
              Every use should reference this website and a complete citation of « Meurant, Laurence (2015), Corpus LSFB. Un corpus informatisé en libre accès de vidéos et d'annotations de la langue des signes de Belgique francophone (LSFB). Laboratoire de Langue des signes de Belgique francophone (LSFB-Lab). FRS-F.N.R.S et Université de Namur. »
            </li>
            <li>
              You may not use the material for commercial purposes.
            </li>
            <li>You can not restrict others from doing anything the license permits</li>
          </ul>

          <div className="flex flex-wrap justify-around">
            <label className="block text-gray-800 font-bold">
              <input className="mr-2 leading-tight" type="checkbox" defaultChecked={this.state.accepted} onChange={() => this.setState({ accepted: !this.state.accepted })} />
              <span className="text-sm">
                I have read and agree to the <a className="text-gray-900 underline hover:text-gray-700" href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noopener noreferrer">terms of use</a>
              </span>
            </label>
          </div>
          <div className="flex flex-wrap justify-around">
            {button}
          </div>

        </div>

      </section >
    );
  }
}


export default DownloadForm;