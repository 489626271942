import React from 'react';
import naltt from './assets/images/LSFB/naltt.png'
import nadi from './assets/images/LSFB/NADI.jpeg'
import precise from './assets/images/LSFB/precise.png'
import DownloadForm from './Download'

function Home(props) {
  return (
    <div>
      <Cover />
      <DatasetDescription />
      <HallOfFame />
      <DownloadForm />
      <About />
      <Cite />
      <LabsLogo />
    </div>
  );
}



function Cover() {
  return (
    <div className="lsfb-img bg-cover lg:min-h-screen">
      <div className="text-white lg:p-20 p-10 xl:w-1/2 w-full">
        <h1 className="text-4xl">Free datasets for Sign Language Recognition</h1>
        <p className="text-justify mb-5">
          Since 2012, the French Belgian Sign Language laboratory (LSFB lab) of the University of Namur collect and translate sign language videos. To support the development of algorithms able to recognize or translate sign languages, we provide currated datasets based on the LSFB corpus.
        </p>
        <a href="#dataset" className="inline-block mt-5 mr-10 text-xl px-4 py-4 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0">
          Datasets
        </a>

        <a href="https://www.corpus-lsfb.be/" className="inline-block mt-5 text-xl px-4 py-4 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0">
          LSFB Corpus
        </a>

      </div>
    </div>
  )
}



function DatasetDescription() {
  return (
    <section id="dataset" className="bg-white py-8">
      <div className="container py-8 px-6 mx-auto">
        <h1 className="uppercase tracking-wide font-bold text-gray-800 text-xl mb-6">
          Datasets
        </h1>
        <p className="mb-8 text-justify">
          The datasets are built upon the <a href="https://www.corpus-lsfb.be/" className="text-teal-500 hover:text-black">LSFB Corpus</a>. The corpus was designed to better understand the evolution and usage of the French Belgian Sign Languages. Since 2012, 90 hours of video were gathered and 25 of them are annotated making it one of the biggest sign language corpus available. The amount of data available could be usefull for the design of sign language recognition algorithm and motivated the construction of two curated dataset : <b>LSFB-ISOL</b> for isolated sign language recognition and <b>LSFB-CONT</b> for continuous sign language recognition.
          <br />
          The annotation is an ongoing process and the datasets will grow over time.
        </p>



        <h2 className="uppercase tracking-wide font-bold text-gray-800 text-l mb-6">LSFB-CONT</h2>

        <p className="mb-8 text-justify">
          The dataset contains video of conversation between two signers. The conversation are not scripted and the signers are free to talk naturally without vocabulary restrictions. Various type of conversations are depicted such as explaination, narration, argumentation. 85 different signers are represented in those conversation.
        </p>


        <h2 className="uppercase tracking-wide font-bold text-gray-800 text-l mb-6">LSFB-ISOL</h2>

        <p className="mb-8 text-justify">
          The dataset for isolated sign recognition contains signs appearing at least 40 times in the continuous dataset.
        </p>

        <a href="/examples" className="inline-block mt-10  bg-teal-500 text-xl px-8 py-4 leading-none border rounded text-white border-white hover:bg-teal-700 mt-4 lg:mt-0">
          Video Sample
        </a>
        <a href="#Download" className="inline-block mt-10 ml-5  bg-teal-500 text-xl px-8 py-4 leading-none border rounded text-white border-white hover:bg-teal-700 mt-4 lg:mt-0">
          Download
        </a>
        <a href="https://pypi.org/project/lsfb-dataset/" className="inline-block mt-10 ml-5  bg-teal-500 text-xl px-8 py-4 leading-none border rounded text-white border-white hover:bg-teal-700 mt-4 lg:mt-0">
          Python Library
        </a>
      </div>

    </section >
  )
}


function HallOfFame() {
  return (
    <section className="bg-white py-8">
      <div className="container py-8 px-6 mx-auto border-t">
        <h1 className="uppercase tracking-wide font-bold text-gray-800 text-xl mb-8">
          Hall of Fame
        </h1>
        <p className="mt-8 mb-8">
          The table shows all the results reported for the LSFB-ISOL dataset. You can report a result by <a className="text-teal-500 hover:text-black" href="mailto:jerome.fink@unamur.be">sending a mail</a>.
        </p>
        <div className="flex justify-center content-center flex-wrap pt-10">
          <div className="p-2 content-center justify-center">
            <table className="table-auto">
              <thead>
                <tr>
                  <td>Authors</td>
                  <td>Paper</td>
                  <td>Code</td>
                  <td>Model</td>
                  <td>Accuracy</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-4 py-2">Jérôme Fink - UNamur</td>
                  <td className="border px-4 py-2"><a className="text-teal-500 hover:text-black" href="https://ieeexplore.ieee.org/abstract/document/9534336">IJCNN</a></td>
                  <td className="border px-4 py-2"><a className="underline" href="https://github.com/Jefidev/Gesture-Recognition-Experiments">Github</a></td>
                  <td className="border px-4 py-2">I3D</td>
                  <td className="border px-4 py-2">51.5%</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Jérôme Fink - UNamur</td>
                  <td className="border px-4 py-2"><a className="text-teal-500 hover:text-black" href="https://ieeexplore.ieee.org/abstract/document/9534336">IJCNN</a></td>
                  <td className="border px-4 py-2"><a className="underline" href="https://github.com/Jefidev/Gesture-Recognition-Experiments">Github</a></td>
                  <td className="border px-4 py-2">C3D</td>
                  <td className="border px-4 py-2">8.6%</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Jérôme Fink - UNamur</td>
                  <td className="border px-4 py-2"><a className="text-teal-500 hover:text-black" href="https://ieeexplore.ieee.org/abstract/document/9534336">IJCNN</a></td>
                  <td className="border px-4 py-2"><a className="underline" href="https://github.com/Jefidev/Gesture-Recognition-Experiments">Github</a></td>
                  <td className="border px-4 py-2">LSTM + CNN</td>
                  <td className="border px-4 py-2">3.6%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section >


  )
}

function About() {
  return (
    <section className="bg-white py-8">
      <div className="container py-8 px-6 mx-auto">
        <h1 className="uppercase tracking-wide font-bold text-gray-800 text-xl mb-8">
          About
        </h1>

        <p className="mb-8">Since 2012, the French Belgian Sign Language laboratories of the University of Namur (LSFB-lab) collect and annotate video of conversation between signers. The purpose of this works is to better understand the usage and evolution of the sign language. The LSFB corpus was created to give access to that collection of video to linguist and citizens. With the rise of AI, we realize that the corpus could also be useful for people working on gesture or sign languge recognition. The video of the corpus were pre-processed and organize to make them easily manipulable with programming languages.
          <br />
          For all the questions related to the datasets or this project do not hesitate to <a className="text-teal-500 hover:text-black" href="mailto:jerome.fink@unamur.be">send us an email</a>.
        </p>

      </div>

    </section>
  )
}


function Cite() {
  return (
    <section className="bg-white py-8">
      <div className="container py-8 px-6 mx-auto">
        <h1 className="uppercase tracking-wide font-bold text-gray-800 text-xl mb-8">
          Cite
        </h1>

        <p className="mb-8">
          Please cite de following article if you use the LSFB-ISOL dataset: <a className="text-teal-500 hover:text-black" href='https://ieeexplore.ieee.org/abstract/document/9534336'>ieeexplore.ieee.org/abstract/document/9534336</a>
        </p>

      </div>

    </section>
  )
}




function LabsLogo() {
  return (
    <section id="labs" className="bg-white py-8">
      <div className="container py-8 px-6 mx-auto">
        <div className="flex flex-wrap justify-around">
          <div>
            <a href="https://naltt.unamur.be/">
              <img src={naltt} className="h-48" alt="Logo NaLTT" />
            </a>
          </div>
          <div>
            <a href="https://www.unamur.be/en/precise">
              <img src={precise} className="h-48" alt="Logo PReCise" />
            </a>
          </div>
          <div>
            <a href="https://nadi.unamur.be/">
              <img src={nadi} className="h-48" alt="Logo NaDI" />
            </a>
          </div>

        </div>
      </div>
    </section >
  )
}


export default Home;